<template>
  <div class="index">
    <div class="banner">
      <el-carousel indicator-position="none" height="10rem" :interval="3000">
        <el-carousel-item v-for="item in bannerList" :key="item.url">
          <img :src="item.min_url" :id="item.id" />
          <!-- <div class="bgBox" :style="{ background: 'url(' + item.url + ')', backgroundSize: '100%' }">
          </div> -->
          <span class="detail" @click="tolink(item)">了解详情</span>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 智能终端 -->
    <div class="terminal">
      <div class="title_box">
        <h3>智能终端</h3>
        <p @click="toTerminal">更多 <i class="el-icon-arrow-right"></i></p>
      </div>
      <div class="item_box">
        <div class="img_box" v-for="(v, k) in terminalList" @click="toTerminalDetail(v, k)" data-aos="slide-up">
          <h2 v-if="v.title">{{ v.title }}</h2>
          <p v-if="v.tip">{{ v.tip }}</p>
          <div v-if="!v.layout" class="link">
            <img src="@/assets/img/home/arrow_right.png" alt="" />
          </div>
          <div v-if="v.layout === 'top'" class="img_box_child">
            <div class="child_img layout_top" v-for="k in v.child" @click="toTerminalChildDetail(k)">
              <h2>{{ k.title }}</h2>
              <p>{{ k.tip }}</p>
              <img class="terminalImg" :src="k.img" :class="k.className" />
              <div class="link">
                <img src="@/assets/img/home/arrow_right.png" alt="" />
              </div>
            </div>
          </div>
          <div v-if="v.layout === 'left'" class="img_box_child">
            <div class="child_img layout_left" v-for="k in v.child" @click="toTerminalChildDetail(k)">
              <img :src="k.img" :class="k.className" />
              <div>
                <h2>{{ k.title }}</h2>
                <p>{{ k.tip }}</p>
              </div>
              <div class="link">
                <img src="@/assets/img/home/arrow_right.png" alt="" />
              </div>
            </div>
          </div>
          <div v-if="(k = 5)">
            <h2>{{ k.title }}</h2>
            <p>{{ k.tip }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 解决方案 -->
      <div class="solution">
        <h3>解决方案</h3>
        <div class="itemBox" v-for="v in solutionList">
          <div
            class="left"
            :style="{
              background: `url(${v.left.img})`,
              backgroundSize: 'cover',
            }"
            @click="toSolution(v)"
            data-aos="fade-up">
            <h4>{{ v.left.title }}</h4>
            <p>{{ v.left.tip }}</p>
          </div>
          <div :class="`right ${v.layout == 3 ? 'layout3' : 'layout4'}`">
            <div class="item_child" v-for="k in v.right" @click="toSolutionDetail(v, k)" data-aos="slide-up">
              <img :src="k.img" />
              <h4>{{ k.title }}</h4>
              <p>{{ k.tip }}</p>
              <div class="detail_btn">查看详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 部分案例 -->
    <h3 style="color: #333333; font-size: 0.28rem; margin-bottom: 0.21rem; padding-left: 3.5rem">部分案例</h3>
    <!-- <div class="casesBox" data-aos="slide-up">
      <el-carousel :interval="2000" type="card" indicator-position="none" height="3.34rem">
        <el-carousel-item v-for="item in casesList" :key="item.title">
          <div class="imgbox">
            <img :src="item.img" />
            <p>{{ item.title }}</p>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <div class="casesBox" data-aos="slide-up">
      <el-carousel indicator-position="none" :interval="4000" height="5rem">
        <el-carousel-item v-for="(item, k) in casesList" :key="k">
          <div class="imgbox">
            <div
              class="gridItem"
              :style="{
                background: `url(${v.img})`,
                backgroundSize: 'cover',
              }"
              v-for="v in item">
              <!-- <img :src="item.img" /> -->
              <p>{{ v.title }}</p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <div class="casesBox">
      <div class="cases" id="casesTob" :style="`width:${4 * casesList.length}rem`">
        <div class="imgbox" v-for="item in casesList">
          <img :src="item.img" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
    <div class="casesBox">
      <div class="cases" id="casesBottom" :style="`width:${4 * casesList.length}rem`">
        <div class="imgbox" v-for="item in casesList">
          <img :src="item.img" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div> -->
    <!-- <MoveBar :data="casesList" :onClick="toCase" /> -->
  </div>
</template>

<script>
import AOS from 'aos'
import { terminalList, solutionList, bannerList, casesList } from '@/assets/data'
import MoveBar from '../components/MoveBar.vue'
export default {
  data() {
    return {
      className: '',
      timer: null,
    }
  },
  components: {
    MoveBar,
  },
  computed: {
    terminalList() {
      return terminalList
    },
    solutionList() {
      return solutionList
    },
    bannerList() {
      return bannerList
    },
    casesList() {
      let newList = []
      for (var i = 0; i < casesList.length; i += 6) {
        newList.push(casesList.slice(i, i + 6))
      }
      return newList
    },
  },
  watch: {},
  mounted() {
    // this.moveFn()
    this.$nextTick(() => {
      AOS.init() // 将 AOS.js 初始化推迟到组件挂载后
    })
    const body = document.body
    body.addEventListener('scroll', () => {
      // AOS.refreshHard();
    })
    this.onloadImageFn()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    onloadImageFn() {
      this.$nextTick(() => {
        this.bannerList.forEach((v) => {
          this.imageFn(v)
        })
      })
    },
    imageFn(v) {
      var img = new Image()
      img.src = v.url
      img.onload = function () {
        let imgEl = document.getElementById(v.id)
        imgEl.src = v.url
      }
    },
    moveFn() {
      let casesTob = document.getElementById('casesTob')
      let casesBottom = document.getElementById('casesBottom')
      let left = 0
      let leftBottom = -83
      this.timer = setInterval(() => {
        left += 0.003
        leftBottom += 0.003
        casesTob.style.left = -left + 'rem'
        casesBottom.style.left = leftBottom + 'rem'
        if (left >= 83) {
          left = 0
          leftBottom = -83
        }
      }, 1)
    },
    toUrl(item) {
      if (item.url) {
        this.$router.push(item.url)
      } else {
        this.$router.push({
          path: '/product_detail',
          query: {
            productName: item.className,
          },
        })
      }
    },
    tolink(item) {
      this.$router.push(item.link)
    },
    toTerminal() {
      this.$router.push('/terminal')
    },
    toTerminalDetail(item, index) {
      if (!item.label) return
      this.$router.push({ path: '/terminal', query: { name: item.label } })
    },
    toTerminalChildDetail(item) {
      this.$router.push({ path: '/terminal', query: { name: item.label } })
    },
    toSolution(item) {
      this.$router.push(item.url)
    },
    toSolutionDetail(p, c) {
      if (p.url === '/smartSocialSecurity') {
        this.$router.push({ path: p.url })
      } else {
        this.$router.push({ path: p.url, query: { name: c.title } })
      }
    },
    toCase(item) {
      this.$router.push({
        path: '/case',
        query: {
          caseName: item.title,
        },
      })
    },
  },
}
</script>
<style scoped lang="less">
.index {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 0.7rem;
  .com_tit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.28rem;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0.42rem;
  }
  .banner {
    width: 100%;
    height: 10rem;

    .el-carousel__item:hover {
      .detail {
        opacity: 1;
      }
    }
    // .bgBox {
    //   width: 100%;
    //   height: 10rem;
    //   background-size: cover;
    // }
    .el-carousel__item img {
      width: 100%;
      height: 100%;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 200px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
    .el-carousel__item .detail {
      display: inline-block;
      position: absolute;
      top: 5.5rem;
      left: 3.2rem;
      width: 2rem;
      height: 0.6rem;
      background: #00a4ea;
      border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
      font-size: 0.22rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.6rem;
      text-align: center;
      // opacity: 0;
      cursor: pointer;
      // transition: opacity 0.4s;
    }
  }
  .terminal {
    padding: 0.6rem 3.55rem 0;
    background: #ffffff;
    .title_box {
      display: flex;
      justify-content: space-between;
      h3 {
        font-size: 0.28rem;
        color: #333333;
        margin-bottom: 0.2rem;
      }
      p {
        font-size: 0.16rem;
        color: #333333;
        line-height: 0.38rem;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          color: #00a4ea;
        }
      }
    }
    .item_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .img_box {
        width: 5.92rem;
        height: 3rem;
        margin-bottom: 0.2rem;
        background-size: cover;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          // animation: scaleimg 0.3s linear;
          // background-size: 110% 110%;
          & > h2 {
            color: #00a4ea;
          }
          .link {
            right: 0;
          }
          // background-size: contain;
          // transform: scale(1.2);
          // background-size: 110% 110%;
        }
        h2 {
          font-size: 0.2rem;
          color: #666666;
          margin: 0.3rem 0 0.1rem 0.2rem;
        }
        p {
          font-size: 0.16rem;
          color: #666666;
          margin-left: 0.2rem;
        }
        .link {
          width: 0.3rem;
          height: 100%;
          position: absolute;
          top: 0;
          right: -0.3rem;
          background: #00a4ea;
          border-radius: 0 0.04rem 0.04rem 0;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: right 0.3s;
          img {
            width: 0.13rem;
            height: 0.24rem;
          }
        }
        .img_box_child {
          display: flex;
          justify-content: space-between;
          height: 100%;
          .child_img {
            height: 100%;
            width: 2.87rem;
            background: #eeeff7;
            border-radius: 0.04rem 0.04rem 0.04rem 0.04rem;
            position: relative;
            overflow: hidden;
            transition: all 1s;
            &:hover {
              .link {
                right: 0;
              }
              h2 {
                color: #00a4ea;
              }
              // .terminalImg {
              //   animation: scalechild_img 0.3s linear;
              //   width: 0.96rem;
              //   height: 1.98rem;
              // }
              // img {
              //   transform: scale(1.2);
              // }
            }
          }
          .layout_top {
            .F11 {
              width: 0.87rem;
            }
            .N1A {
              width: 0.64rem;
            }
            img {
              height: 1.8rem;
              margin: 0.12rem auto 0;
              display: block;
            }
          }
          .layout_left {
            display: flex;
            h2 {
              margin: 0.61rem 0 0 0;
            }
            p {
              margin: 0.1rem 0 0 0;
            }
            .T10-F {
              width: 0.77rem;
              height: 1.24rem;
              margin: 0.28rem 0.1rem 0 0.3rem;
            }
            .T10-P {
              width: 1.24rem;
              height: 1rem;
              margin: 0.48rem 0.1rem 0 0.19rem;
            }
          }
          .link {
            width: 0.3rem;
            height: 100%;
            position: absolute;
            top: 0;
            right: -0.3rem;
            background: #00a4ea;
            border-radius: 0 0.04rem 0.04rem 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: right 0.3s;
            img {
              width: 0.13rem;
              height: 0.24rem;
            }
          }
        }
      }

      // @keyframes scalechild_img {
      //   from {
      //     width: 0.87rem;
      //     height: 1.8rem;
      //   }
      //   to {
      //     width: 0.96rem;
      //     height: 1.98rem;
      //   }
      // }
      // @keyframes scaleimg {
      //   from {
      //     background-size: 100% 100%;
      //   }
      //   to {
      //     background-size: 110% 110%;
      //   }
      // }
      .img_box:nth-child(1) {
        background-image: url('../assets/img/home/terminal1.png');
      }
      .img_box:nth-child(2) {
        background-image: url('../assets/img/home/terminal2.png');
      }
      .img_box:nth-child(3) {
        background-image: url('../assets/img/home/terminal3.png');
      }
      .img_box:nth-child(5) {
        height: 1.8rem;
      }
      .img_box:nth-child(6) {
        height: 1.8rem;
        background-image: url('../assets/img/home/terminal4.png');
        h2 {
          margin: 0.65rem 0 0.1rem 0.4rem;
        }
        p {
          margin-left: 0.4rem;
        }
      }
    }
  }
  .content {
    padding: 0 3.55rem;

    .solution {
      padding: 0.6rem 0 0.2rem 0;
      h3 {
        font-size: 0.28rem;
        color: #333333;
        margin: 0 0 0.21rem 0;
      }
      .itemBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.6rem;
        .left {
          width: 2.87rem;
          height: 6.5rem;
          background-size: cover;
          color: #fff;
          cursor: pointer;
          h4 {
            margin: 0.4rem 0 0.12rem 0.3rem;
            font-size: 0.19rem;
            margin-bottom: 0.12rem;
          }
          p {
            font-size: 0.16rem;
            // margin: 0 0 0 0.3rem;
            padding: 0 0.3rem;
          }
        }
        .right {
          width: 8.97rem;
          height: 6.5rem;
          display: grid;

          .item_child {
            background: #fff;
            text-align: center;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            transition: all 0.3s;
            img {
              width: 0.9rem;
              height: 0.9rem;
              display: block;
              margin: 0.56rem auto 0;
            }
            h4 {
              font-size: 0.19rem;
              margin: 0.36rem 0 0.12rem 0;
              color: #666666;
            }
            p {
              padding: 0 0.2rem;
              font-size: 0.16rem;
              color: #666666;
            }
            .detail_btn {
              align-items: center;
              width: 100%;
              height: 0.5rem;
              line-height: 0.5rem;
              box-sizing: border-box;
              padding: 0 0.18rem;
              font-size: 0.16rem;
              font-weight: 400;
              color: #ffffff;
              position: absolute;
              transition: all 0.3s;
              left: 0;
              bottom: -0.5rem;
              background: #00a4ea;
            }
            &:hover {
              position: relative;
              box-shadow: 0 0.08rem 0.29rem 0 rgba(165, 178, 198, 0.3);
              h4 {
                color: #00a4ea;
              }
              .detail_btn {
                bottom: 0;
              }
            }
          }
        }
        .layout3 {
          grid-template-rows: 3.1rem 3.1rem 3.1rem;
          grid-template-columns: 2.87rem 2.87rem 2.87rem;
          grid-gap: 0.3rem 0.18rem;
        }
        .layout4 {
          grid-template-rows: 3.1rem 3.1rem;
          grid-template-columns: 4.39rem 4.39rem;
          grid-gap: 0.3rem 0.18rem;
        }
      }
    }
  }
  .casesBox {
    width: calc(100% - 7.1rem);
    overflow: hidden;
    margin: 0.2rem auto 0;
    position: relative;
    // height: 3.24rem;
    .imgbox {
      // width: 5.8rem;
      width: 100%;
      // height: 3.04rem;
      overflow: hidden;
      position: relative;
      // margin-right: 0.3rem;

      display: grid;
      grid-template-rows: 2.4rem 2.4rem 2.4rem;
      grid-template-columns: 3.8rem 3.8rem 3.8rem;
      grid-gap: 0.2rem 0.33rem;
      .gridItem {
        position: relative;
        //   // border: 0.02rem solid #00a4ea;
        //   &::after {
        //     background: linear-gradient(to right, #007eff 0%, #00ecff 100%);
        //     mix-blend-mode: overlay;
        //     opacity: 0.2;
        //     z-index: 20;
        //   }
      }
      .gridItem::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .gridItem::after {
        background: linear-gradient(to right, #007eff 0%, #00ecff 100%);
        mix-blend-mode: overlay;
        opacity: 0.2;
        z-index: 20;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      // &:hover {
      //   img {
      //     transform: scale(1.1);
      //   }
      //   p {
      //     bottom: 0;
      //   }
      // }
      // img {
      //   width: 100%;
      //   height: 100%;
      //   transition: transform 0.6s;
      // }
      p {
        width: 100%;
        font-size: 0.16rem;
        // font-weight: bold;
        color: #000000;
        background: rgba(#fff, 1);
        line-height: 0.4rem;
        position: absolute;
        bottom: 0rem;
        left: 0;
        text-align: center;
      }
    }
    .cases {
      display: flex;
      flex-wrap: nowrap;
      position: absolute;
      top: 0;
      left: 0;
    }
    #casesBottom {
      left: -37rem;
    }
  }
}
</style>
