<template>
  <div class="move-bar">
    <div class="scroll" ref="scroll">
      <div
        class="move-item"
        v-for="(item, index) in data"
        :class="getClassName(index)"
        @click="handleClick(item)"
      >
        <img :src="item.img" />
        <div class="text">
          <span>{{ item.title }}</span>
          <p>{{ item.desc }}</p>
        </div>
        <div class="bottom-text">{{ item.title }}</div>
      </div>
    </div>

    <div class="button prev" @click="handlePrev"></div>
    <div class="button next" @click="handleNext"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    onClick: {
      type: Function,
      default: () => () => {},
    },
  },
  data() {
    return {
      currentShow: 0,
    };
  },
  methods: {
    getClassName(index) {
      let className = [];
      className.push(this.currentShow === index ? "open" : "close");
      className.push(
        index - 1 === this.currentShow || index === this.currentShow
          ? "show"
          : "hidden"
      );
      return className.join(" ");
    },
    handlePrev() {
      if (this.currentShow > 0) {
        this.currentShow--;
        this.$refs.scroll.style.left =
          -4.5 * this.currentShow + 3.5 - this.currentShow * 0.1 + "rem";
      }
    },
    handleNext() {
      if (this.currentShow < 3) {
        this.currentShow++;
        this.$refs.scroll.style.left =
          -4.5 * this.currentShow + 3.5 - this.currentShow * 0.1 + "rem";
      }
    },
    handleClick(item) {
      this.onClick(item);
    },
  },
};
</script>

<style lang="less" scoped>
.move-bar {
  position: relative;
  margin-bottom: 0.5rem;
  .scroll {
    position: relative;
    width: 100%;
    display: flex;
    column-gap: 0.6rem;
    left: 3.5rem;
    transition: all 1s ease;
  }
  .move-item {
    flex-shrink: 0;
    overflow: hidden;
    width: 8rem;
    height: 4.5rem;
    display: flex;
    position: relative;
    box-sizing: border-box;
    transition: all 0.3s;
    cursor: pointer;
    img {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 8rem;
      height: 4.5rem;
    }
    &::after {
      z-index: 1;
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: -webkit-linear-gradient(
        left,
        #00a4ea 50%,
        rgba(1, 169, 228, 0)
      );
    }

    .text {
      z-index: 2;
      position: absolute;
      top: 1rem;
      left: 0.5rem;
      width: 8rem;
      overflow: hidden;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      span {
        position: relative;
        font-size: 0.32rem;
        margin-bottom: 0.2rem;
      }
      p {
        position: relative;
        font-size: 0.22rem;
        width: 70%;
      }
    }
    .bottom-text {
      z-index: 2;
      position: absolute;
      text-align: center;
      color: #ffffff;
      font-size: 0.32rem;
      opacity: 0;
      width: 3rem;
      left: 0.5rem;
      bottom: 0.3rem;
    }
  }
  .close {
    animation-name: close;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    &:after {
      animation-name: AfterClose;
      animation-duration: 300ms;
      animation-fill-mode: forwards;
    }
    .text {
      display: none;
    }
    .bottom-text {
      animation-name: fade-in;
      animation-duration: 1000ms;
      animation-fill-mode: forwards;
    }
  }
  .open {
    width: 4rem;
    animation-name: open;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    .bottom-text {
      opacity: 1;
      animation-name: fade-out;
      animation-duration: 300ms;
      animation-fill-mode: forwards;
    }
    .text {
      span {
        opacity: 0;
        animation-name: fade-move-in;
        animation-duration: 1000ms;
        animation-fill-mode: forwards;
        animation-delay: 300ms;
      }
      p {
        opacity: 0;
        animation-name: fade-move-in;
        animation-duration: 1000ms;
        animation-fill-mode: forwards;
        animation-delay: 500ms;
      }
    }
  }
  .show {
    opacity: 1;
  }
  .hidden {
    opacity: 0.2;
  }
  .button {
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    position: absolute;
    z-index: 3;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    &.prev {
      left: 0.5rem;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("../assets/img/button.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    &.next {
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      background-image: url("../assets/img/button.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  @keyframes AfterClose {
    10% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 54%,
        rgba(1, 169, 228, 0)
      );
    }
    20% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 48%,
        rgba(1, 169, 228, 0)
      );
    }
    30% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 42%,
        rgba(1, 169, 228, 0)
      );
    }
    40% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 36%,
        rgba(1, 169, 228, 0)
      );
    }
    50% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 30%,
        rgba(1, 169, 228, 0)
      );
    }
    60% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 24%,
        rgba(1, 169, 228, 0)
      );
    }
    70% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 18%,
        rgba(1, 169, 228, 0)
      );
    }
    80% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 12%,
        rgba(1, 169, 228, 0)
      );
    }
    90% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 6%,
        rgba(1, 169, 228, 0)
      );
    }
    100% {
      background: -webkit-linear-gradient(
        left,
        #00a4ea 0%,
        rgba(1, 169, 228, 0)
      );
    }
  }
  @keyframes fade-in {
    100% {
      opacity: 1;
    }
  }
  @keyframes close {
    100% {
      width: 4rem;
    }
  }
  @keyframes fade-out {
    100% {
      opacity: 0;
    }
  }
  @keyframes open {
    100% {
      width: 8rem;
    }
  }
  @keyframes fade-move-in {
    0% {
      opacity: 0;
      left: 1rem;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }
}
</style>
